.navigation {
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.navigation_inside {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 1240px;
    margin: auto;
}

.navigation_inside ul {
    display: flex;
    margin-bottom: 10px;
}

.navigation_inside li {
    list-style-type: none;
    padding: 20px;
    color: #212121;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1.2px;
    text-transform: uppercase;
    >span {
        transition: all 0.3s ease;
    }
}

.navigation_inside li:hover {
    >span {
        border-bottom: 2px solid rgb(254, 125, 11);
        padding-bottom: 5px;
    }
}

.nav-active {
    li>span {
        border-bottom: 2px solid rgb(254, 125, 11);
        padding-bottom: 5px;
    }
}

.menu_gauche img {
    width: 190px;
    height: 65px;
    margin-left: 20px;
}

@media screen and (max-width: 1050px) {
    .menu_droite ul {
        display: none;
    }
    .navigation_inside {
        width: 100%;
        justify-content: center;
    }
    .navigation {
        position: relative;
        width: 100%;
    }
    .menu_gauche img {
        margin-left: 0px;
    }
}