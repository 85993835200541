.posts_post {
    display: flex;
    border: 1px solid rgba(136, 132, 136, 0.3);
    margin-bottom: 30px;
}

.posts_post_img img {
    height: 100%;
    width: 484px;
    object-fit: cover;
}

.posts_trait {
    height: 1px;
    background-color: gray;
    width: 100%;
    opacity: 0.6;
    margin-top: 15px;
}

.posts_details {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    margin-right: 86px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    .realisation_details{
        margin-top: 20px;
        >p{
            margin-top: 10px;
            >i{
                margin-right: 20px;
            }
        }
    }
    .realisation_ville{
        font-size: 25px;
        margin-top: 15px;
    }
    >p {
        >span {
            font-size: 12px;
        }
    }
    h2 {
        font-weight: 400;
        font-size: 26px;
        margin-top: 20px;
        line-height: 34px;
    }
}

.posts_details_p {
    margin-top: 20px;
    font-size: 14px;
    opacity: 0.6;
}

.posts_vue_like_comment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.post_vue_like {
    display: flex;
    width: 180px;
    justify-content: space-between;
}

.post_vue_like p {
    font-size: 13px;
}

.like {
    display: flex;
    align-items: center;
    width: 30px;
    justify-content: space-between;
    >p {
        font-size: 13px;
    }
    i {
        font-size: 15px;
        cursor: pointer;
    }
    >.fas{
        color: red;
    }
}

.arrow_back {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 1200px;
    margin: auto;
    height: 100px;
    i {
        color: black;
        border: 1px solid black;
        font-size: 25px;
        height: 40px;
        width: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        transition: all 0.1s ease-in-out;
    }
}

.arrow_back i:hover {
    background-color: black;
    color: white;
}

.title_chantiers_realisation{
    >a{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 50px;
        text-decoration: underline;
        text-transform: uppercase;
        color: black;
    }
}

.authLike{
    text-align: right;
    font-size: 14px;
    color: red;
    margin-bottom: 10px;
}

@media screen and (max-width: 1050px) {
    .posts_post {
        flex-direction: column;
        margin: auto;
        width: 80%;
    }
    .posts_details {
        margin-left: 0px;
        width: 100%;
        >p{
            margin-left: 10px;
        }
        .realisation_details{
            margin-top: 20px;
            >p{
                margin-top: 10px;
                font-size: 13px;
                margin-left: 10px;
                >i{
                    margin-right: 10px;
                    padding: 0px;
                }   
            }
        }

        .posts_details_p {
            margin-top: 20px;
            font-size: 12px;
            width: 80%;
            margin-left: 10px;
        }

        .realisation_ville{
            font-size: 25px;
            margin-top: 15px;
            text-align: center;
            font-size: 20px;
        }
        >p {
            width: 100%;
            >span {
                font-size: 12px;
            }
        }
        h2 {
            font-size: 22px;
        }
    }
    .arrow_back {
        display: none;
    }
    .posts_post_img img {
        width: 100%;
    }
    .posts_vue_like_comment {
        width: 95%;
    }
    .post_vue_like {
        display: flex;
        width: 180px;
        justify-content: space-between;
        margin-left: 10px;
    }
    .posts_trait {
        width: 100%;
    }
    .title_chantiers_realisation{
        margin-top: 20px;
        >a{
            font-size: 13px;
        }
    }
    .authLike{
        text-align: center;
        font-size: 13px;
    }
}