.video_chantier {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.video_chantier h1 {
    font-weight: 100;
    font-size: 44px;
}

.video_chantier video {
    width: 100%;
    height: 650px;
    object-fit: cover;
    filter: brightness(60%);
    z-index: -1;
}

.trait_h1 {
    height: 1px;
    background: white;
    width: 450px;
    margin-top: 10px;
}

h1.h1_nos_metier {
    margin-top: 420px;
    font-size: 50px;
    font-weight: bolder;
    color: #8a8888;
}

.trait_home {
    height: 6px;
    background: black;
    width: 100px;
}

.mon_titre {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    margin-top: -400px;
    animation: apparition 0.8s ease-out;
}

@keyframes apparition {
    from {
        opacity: 0;
        transform: translateY(-100px);
    }
    to {
        opacity: 1;
        transform: translateY(0px);
    }
}

.mon_titre p {
    margin-top: 20px;
    font-size: 22px;
    font-weight: 100;
}

h1.title_derniers_projets {
    margin-top: 80px;
    font-size: 50px;
    font-weight: bolder;
    color: #8a8888;
}

.home_lien_posts {
    text-transform: uppercase;
    width: 100%;
    margin-left: 30px;
    margin-right: 30px;
    border-bottom: 2px solid rgb(254, 125, 11);
    padding-bottom: 5px;
    margin-top: 10px;
    >a {
        list-style-type: none;
        color: black;
    }
}

@media screen and (max-width: 1050px) {
    .home_lien_posts {
        width: 200px;
        li {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    .video_chantier h1 {
        text-align: center;
    }
    h1.h1_nos_metier {
        width: 100%;
        font-size: 30px;
        margin-top: 300px;
    }
    .trait_h1 {
        width: 200px;
    }
    .mon_titre p {
        width: 80%;
        text-align: center;
    }
    .mon_titre {
        width: 100%;
    }
    .video_chantier video {
        width: 100%;
    }
    h1.title_derniers_projets {
        margin-top: 70px;
        font-size: 30px;
        font-weight: bolder;
        color: #8a8888;
    }
}