h4 {
    color: red;
}

.contact {
    display: flex;
    justify-content: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.9)), url("../../images/building.jpg");
    background-position: right 20% bottom 0%;
    background-size: cover;
    background-repeat: no-repeat;
    background-clip: padding-box;
    width: 100%;
    height: 700px;
}

.contact form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 400px;
    width: 1000px;
    margin-top: 120px;
}

.contact form input {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 20px;
    width: 400px;
    height: 50px;
    border: transparent;
    background: transparent;
    border: 1px solid #ced4da;
    box-shadow: 0 0 3px #ced4da;
    outline-offset: 0px;
    outline: none;
    border-radius: 5px;
    color: white;
    padding: 20px;
}

.contact form input::placeholder {
    color: white;
}

input:focus::placeholder {
    opacity: 0;
}

.contact form textarea {
    width: 840px;
    height: 150px;
    margin-top: 20px;
    border: transparent;
    background: transparent;
    border: 1px solid #ced4da;
    box-shadow: 0 0 3px #ced4da;
    outline: none;
    outline-offset: 0px;
    padding: 20px;
    color: white;
    border-radius: 5px;
}

.contact textarea:focus::placeholder {
    opacity: 0;
}

.contact textarea::placeholder {
    color: white;
}

.form_button {
    display: flex;
    flex-direction: column;
    >button {
        width: 20%;
        margin: auto;
        margin-top: 20px;
        padding: 12px;
        border-radius: 5px;
        border: transparent;
        background: transparent;
        border: 1px solid #ced4da;
        box-shadow: 0 0 3px #ced4da;
        outline: none;
        color: white;
        cursor: pointer;
        transition: all 0.3s ease-in-out;
    }
    >button:hover {
        background: white;
        color: black;
    }
}

.message_envoye{
    text-align: center;
    color: green;
    margin-top: 10px;
}

.error_contact{
    color: rgb(199, 41, 41);
    margin-left: 21px;
    margin-top: 10px;
    font-size: 14px;
}

@media screen and (max-width: 1024px) {
    .contact form {
        width: 280px;
        flex-wrap: nowrap;
    }
    .contact form textarea {
        width: 280px;
    }
    .form_button {
        >button {
            width: 40%;
        }
    }


    .contact form input {
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 20px;
        width: 280px;

    }
}