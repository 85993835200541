.form-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 700px;
    border-radius: 18px;
    margin-top: 40px;
    border: 1px solid rgba(136, 132, 136, 0.7);
    margin-bottom: 40px;
    >ul {
        height: 90%;
    }
    >ul li {
        list-style-type: none;
        margin: 16px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        padding: 10px;
    }
}

.form-group-checkbox{
    
    align-items: center;
    margin-left: 25px;
    margin-top: 20px;
    margin-bottom: 10px;
    >.label-agree-term{
        margin-left: 25px;
    }
}

.form-group input {
    border: none;
    border-bottom: 1px solid black;
    background: none;
    margin: 24px;
    height: 40px;
    outline: none;
}

.form-group input::placeholder {
    color: black;
}

.active-btn {
    background: rgb(0, 0, 238);
    color: white;
}

.label-agree-term {
    font-size: 15px;
}

.signin-form {
    margin-top: 50px;
}

.form-button {
    height: 60px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.form-button input {
    border: 1px solid white;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;
    background-color: rgb(0, 0, 238);
    color: white;
    margin-top: 30px;
}

.signup {
    height: 100%;
    width: 25%;
}

.signup-form {
    margin-top: 50px;
}

.error_email {
    margin-left: 25px;
    color: red;
}

.auth_image{
    height: 85%;
}

.error_msg_signup{
    margin-left: 25px;
    color: red;
    font-size: 15px;
}

.error_msg_signup_condition{
    color: red;
    font-size: 14px;
    margin-top: 10px;
}

.registration_success{
    color: green;
}

@media screen and (max-width: 1024px) {
    .auth_image{
        //display: none;
        margin-top: 80px;
        margin-bottom: 10px;
        >img{
            width: 150px;
        }
    }

    .signin-form {
        margin-top: 0px;
    }

    .signup-form {
        margin-top: 0px;
    }


    .form-container {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        height: 100%;
        border-radius: 18px;
        margin-top: 40px;
        border: 1px solid rgba(136, 132, 136, 0.7);
        padding-bottom: 60px;
        >ul {
            height: 90%;
            >li{
                width: 100%;
            }
        }

    }

    form{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .form-title{
        width: 51%;
        text-align: center;
        font-size: 20px;
        margin-top: 40px;
        text-transform: uppercase;
        font-weight: bold;
    }
}

@media screen and (max-width: 1024px) {
    .form-group-checkbox{
        display: none;
    }
}