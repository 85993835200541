$cards_width: 600px;
$cards_height: 400px;
.project {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 40px;
}

.project_cards {
    width: $cards_width;
    height: $cards_height;
    position: relative;
    overflow: hidden;
    margin: 20px;
}

.project_cards_front img {
    width: $cards_width;
    height: $cards_height;
    object-fit: cover;
    transition: all 0.5s ease-in-out;
}

.project_cards_hover {
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: $cards_width;
    height: $cards_height;
    transform: translateY(100%);
    transition: all 0.5s ease-in-out;
    >.project_cards_hover_content {
        color: white;
        display: flex;
        justify-content: center;
        flex-direction: column;
        height: 20%;
        width: 80%;
        padding: 20px;
        filter: brightness(100%);
        >p {
            margin-top: 20px;
            font-weight: 100;
            font-size: 16px;
        }
        >a {
            color: rgb(254, 125, 11);
            margin-top: 60px;
            font-weight: bolder;
            margin-left: 3px;
        }
    }

}



.project_cards:hover {
    .project_cards_hover {
        transform: translateY(0%);
    }
    .project_cards_front img {
        filter: brightness(30%);
    }
}

@media screen and (max-width: 1050px) {
    .project {
        flex-direction: column;
        width: 100%;
        margin: 0;
        margin-bottom: 12px;
    }
    .project_cards {
        margin-top: 20px;
    }
    .project_cards_front {
        display: flex;
        justify-content: center;
        >img{
            width: 90%;
        }
        
    }
    .project_cards {
        width: 100%;
        margin: 0;
        margin-top: 40px;
    }
    .project_cards_hover {
        >.project_cards_hover_content {
            width: 100%;
            height: 100%;
            margin-left: 15px;
            >p {
                width: 50%;
            }
            >h2{
                width: 60%;

            }
        }
    }
}